var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"body"},[_c('h2',{staticClass:"title"},[_vm._v("Sous-Traitant")]),_c('h3',{attrs:{"id":"ajout"}},[_vm._v("Ajouter un Sous-Traitant")]),_c('h4',[_vm._v(" Bienvenue chez Batigo ! Si vous souhaitez ajouter un Sous-Traitants, veuillez suivre les étapes ci-dessous : ")]),_vm._m(0),_c('p',[_vm._v("tous les champs (*) sont obligatoires")]),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1683708195_Ajouter un Sous-Traitants.mp4`,"controls":""}})]),_c('h3',{attrs:{"id":"edit"}},[_vm._v("Modifier un Sous-Traitant")]),_c('h4',[_vm._v(" Bienvenue chez Batigo ! Si vous souhaitez modifier les informations d'un sous-traitant, veuillez suivre les étapes ci-dessous : ")]),_vm._m(1),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1683708269_Modifier un sous-traitant.mp4`,"controls":""}})]),_c('p',[_vm._v(" Veuillez noter que si le sous-traitant a déjà été utilisé dans un devis, une facture, les modifications apportées à ses informations ne seront pas reflétées dans les documents existants. Vous devrez mettre à jour manuellement les informations dans ces documents si nécessaire. ")]),_c('h3',{attrs:{"id":"delete"}},[_vm._v("Supprimer un Sous-Traitant")]),_c('h4',[_vm._v(" Si vous souhaitez supprimer un sous-traitant, veuillez suivre les étapes ci-dessous : ")]),_vm._m(2),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1683708311_Supprimer un sous-traitant.mp4`,"controls":""}})]),_c('p',[_vm._v(" N'oubliez pas que cette action est irréversible et que toutes les informations associées au type de pièce seront également supprimées. ")]),_c('div',{staticClass:"bulle"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"33","height":"33","viewBox":"0 0 33 33"}},[_c('g',{attrs:{"id":"Icon_feather-info","data-name":"Icon feather-info","transform":"translate(-1.5 -1.5)"}},[_c('path',{attrs:{"id":"Tracé_78064","data-name":"Tracé 78064","d":"M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78065","data-name":"Tracé 78065","d":"M18,24V18","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78066","data-name":"Tracé 78066","d":"M18,12h0","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}})])]),_vm._v(" Nous espérons que ces instructions vous ont été utiles. Si vous avez des questions ou des problèmes avec un sous-traitant, n'hésitez pas à nous contacter via notre email de contact. Merci de nous faire confiance et à bientôt sur Batigo ! ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la section \"Sous-Traitants\" dans le menu principal.")]),_c('li',[_vm._v("Cliquez sur le bouton \"Nouveau\".")]),_c('li',[_vm._v("Saisissez le nom du sous-traitant dans le champ \"Nom\".")]),_c('li',[_vm._v("Saisissez le numéro Siret dans le champ \"Siret\".")]),_c('li',[_vm._v("Saisissez le numéro RGE.")]),_c('li',[_vm._v("Cliquez sur \"Enregistrer\" pour ajouter le sous-traitant.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v(" Accédez à la section \"Sous-traitants\" de l'interface d'administration. ")]),_c('li',[_vm._v("Recherchez le sous-traitant que vous souhaitez modifier.")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Modifier\" correspondant au sous-traitant sélectionné. ")]),_c('li',[_vm._v(" Modifiez les informations nécessaires dans le formulaire de modification. ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\" pour sauvegarder les modifications. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la liste des sous-traitants dans le menu principal.")]),_c('li',[_vm._v("Recherchez le sous-traitant que vous souhaitez supprimer.")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Supprimer\" à côté du sous-traitant que vous souhaitez supprimer. ")]),_c('li',[_vm._v(" Confirmez la suppression en cliquant sur le bouton \"Supprimer\" dans la boîte de dialogue de confirmation. ")]),_c('li',[_vm._v(" La suppression sera effectuée et le sous-traitant ne sera plus visible dans la liste des sous-traitants. ")])])
}]

export { render, staticRenderFns }